<template>
    <div class="ytm-material-wrapper">
        <TestViewForParticipant v-if="!isTestAdmin" :testId="material.id" :test="material.material"/>
        <TestViewForAdmin v-if="isTestAdmin" :testId="material.id" :test="material.material"/>
    </div>
</template>

<script>
import TestViewForParticipant from "@/components/MaterialsV2/Test/TestView/TestViewForParticipant.vue";
import TestViewForAdmin from "@/components/MaterialsV2/Test/TestView/TestViewForAdmin.vue";

export default {
    name: 'Test',
    components: {TestViewForAdmin, TestViewForParticipant},
    props: {
        material: {
            type: Object,
            required: true,
        },
        isTestAdmin: {
            type: Boolean,
            required: true,
        },
    },
};
</script>

<style scoped>
.ytm-material-wrapper {
    margin: 0 0 1rem;
    padding: 0.75rem 1rem;
    border-radius: 5px;
    border: 1px solid #e9ecef;
    transition: 0.1s all ease-in-out;
}
.ytm-material-wrapper:hover {
    border-color: #68cef8;
}
</style>