<template>
    <div>
        <QuestionHeadView :head="question.head" style="margin-bottom: 0.4rem"/>
        <p class="ytm-default-text ytm-question-desc" style="margin-bottom: 0.9rem">
            Выберите единственный правильный ответ
        </p>
        <div v-if="ready" class="ytm-answer">
            <AnswerOptionView
                v-for="option in question.answer_options"
                :key="option.id"
                :content="option.content"
                :isChecked="checked[option.id]"
                @update:isChecked="onAnswerUpdate(option.id, $event)"
            />
        </div>
    </div>
</template>

<script>
import QuestionHeadView from "@/components/MaterialsV2/Test/TestView/QuestionHeadView.vue";
import AnswerOptionView from "@/components/MaterialsV2/Test/TestView/AnswerOptionView.vue";

export default {
    name: 'SingleChoiceView',
    components: {AnswerOptionView, QuestionHeadView},
    props: {
        question: {
            type: Object,
            required: true,
        },
        answerDraft: {
            type: Number,
            required: true,
        },
    },
    data() {
        return {
            ready: false,
            checked: {},
        };
    },
    methods: {
        onAnswerUpdate(optionId, newVal) {
            if (newVal) {
                this.$emit('update:answerDraft', optionId);
                for (const option of this.question.answer_options) {
                    this.checked[option.id] = optionId === option.id;
                }
            }
        },
    },
    mounted() {
        for (const option of this.question.answer_options) {
            this.checked[option.id] = this.answerDraft === option.id;
        }
        this.ready = true;
    },
};
</script>

<style scoped>
.ytm-question-desc {
    font-size: 0.8rem;
    color: #9E9E9E;
}
.ytm-answer {
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
}
</style>