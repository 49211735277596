<template>
  <div class="ytm-answer-option-editor" @click="toggleIsChecked">
      <div class="ytm-choice-wrapper">
          <div :class="{'ytm-choice': true, 'ytm-choice-checked': isChecked}"/>
      </div>
      <div class="ytm-answer-option-content">
          <LightEditor
              v-model="content"
              placeholder="42"
              @update:modelValue="onUpdate"
              autofocus="end"
              @click.stop
          />
      </div>
      <div class="ytm-delete-option-wrapper">
          <i class="fa-solid fa-xmark ytm-delete-option ytm-red" @click="deleteAnswerOption()"/>
      </div>
  </div>
</template>

<script>
import LightEditor from "@/components/redesign/Form/LightEditor.vue";

export default {
    name: 'AnswerOptionEditor',
    components: {LightEditor},
    props: {
        content: {},
        isChecked: {
            type: Boolean,
            required: true,
        },
    },
    methods: {
        toggleIsChecked() {
            this.$emit('update:isChecked', !this.isChecked);
        },
        onUpdate(value) {
            this.$emit('update:content', value);
        },
        deleteAnswerOption() {
            this.$emit('delete');
        },
    },
};
</script>

<style scoped>
.ytm-answer-option-editor {
    width: 100%;
    display: flex;
}
.ytm-choice-wrapper {
    padding-right: 1rem;
    display: flex;
    align-items: center;
    cursor: pointer;
}
.ytm-choice {
    width: 1rem;
    height: 1rem;
    border: 1px solid #adb5bd;
    border-radius: 0.25rem;
    background-repeat: no-repeat;
    background-position: center center;
    background-size: 50% 50%;
}
.ytm-choice-checked {
    border-color: #2EA95F;
    background-color: #FFFFFF;
    background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 8 8'%3e%3cpath fill='%232EA95F' d='M6.564.75l-3.59 3.612-1.538-1.55L0 4.26 2.974 7.25 8 2.193z'/%3e%3c/svg%3e");
}
.ytm-answer-option-content {
    flex-grow: 1;
}
.ytm-delete-option-wrapper {
    display: flex;
    align-items: center;
    margin-left: 0.7rem;
}
.ytm-delete-option {
    font-size: 1.2rem;
    cursor: pointer;
    opacity: 0;
    transition: opacity .1s ease-in-out;
}
.ytm-answer-option-editor:hover .ytm-delete-option {
    opacity: 1;
}
</style>