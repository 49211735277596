import {authHeader} from "@/util/auth-header";
import axios from "axios";
import {SERVICE_MATERIALS_URI} from "@/util/api-host";
import store from "../../store";

export default async function publishMaterial(roomId, draft) {
    let payload = {};
    let headers = {};
    if (draft.type === 'text') { // temporary deprecated
        // payload = {
        //     text: this.$refs['draft_' + draft.id][0].editor.getHTML(),
        // };
    } else if (draft.type === 'images') {
        if (draft.files.length === 0) {
            return;
        }
        payload = new FormData();
        for (let i = 0; i < draft.files.length; i++) {
            payload.append('images[]', draft.files[i]);
        }
        payload.append('description', draft.description.trim());
        headers = {
            ...headers,
            'Content-Type': 'multipart/form-data',
        };
    } else if (draft.type === 'test') {
        payload = {
            test: draft.content,
        };
    } else if (draft.type === 'code') {
        payload = {
            lang: 'text/plain',
        };
    } else if (draft.type === 'ctext') {
        payload = {};
    } else if (draft.type === 'board') {
        payload = {};
    }
    await store.dispatch('auth/autoLogin');
    headers = {
        ...headers,
        ...authHeader(),
    };
    await axios.post(
        SERVICE_MATERIALS_URI + '/materials',
        payload,
        {
            headers: headers,
            params: {
                materials_id: roomId,
                type: draft.type,
            },
        },
    );
}