<template>
    <div class="ytm-attempt-view">
        <div v-if="attempt.finished" class="ytm-attempt-head" @click="toggleShowAttempt">
            <p class="ytm-default-text" style="flex-grow: 1">
                <span style="font-weight: 600">{{attempt.username}}</span> завершил(а) попытку
            </p>
            <div class="ytm-attempt-view-icon-wrapper">
                <svg viewBox="0 0 21 36" fill="none" xmlns="http://www.w3.org/2000/svg" class="ytm-attempt-view-icon" ref="icon">
                    <path d="M3 3.5L17.75 18.25L3 33" stroke="black" stroke-opacity="0.84" stroke-width="5.7561" stroke-linecap="round" stroke-linejoin="round"/>
                </svg>
            </div>
        </div>
        <div v-else class="ytm-attempt-head">
            <p class="ytm-default-text">
                <span style="font-weight: 600">{{attempt.username}}</span> в процессе
            </p>
        </div>
        <ExpandCollapseTransition>
            <div v-if="showAttempt && ready" class="ytm-questions-wrapper" style="margin-top: 1rem">
                <div
                    v-for="(question, index) in test.questions"
                    :key="question.id"
                    class="ytm-question-wrapper-v2"
                    style="margin-bottom: 0"
                >
                    <p class="ytm-default-text ytm-question-label">
                        Вопрос {{ index + 1 }}
                    </p>
                    <SingleChoiceResult
                        v-if="question.type === 'single_choice'"
                        :question="question.single_choice"
                        :answer="attempt.answers[index].single_choice.answer"
                        style="margin-bottom: 1rem"
                    />
                    <MultipleChoiceResult
                        v-if="question.type === 'multiple_choice'"
                        :question="question.multiple_choice"
                        :answer="attempt.answers[index].multiple_choice.answer"
                        style="margin-bottom: 1rem"
                    />
                    <ShortAnswerResult
                        v-if="question.type === 'short_answer'"
                        :question="question.short_answer"
                        :answer="attempt.answers[index].short_answer.answer"
                    />
                    <DetailedAnswerResult
                        v-if="question.type === 'detailed_answer'"
                        :questionId="`${testId}:${index}`"
                        :question="question.detailed_answer"
                        :answer="attempt.answers[index].detailed_answer.answer"
                        :board="attempt.answers[index].detailed_answer.board"
                    />
                    <div style="display: flex; gap: 0.5rem">
                        <div style="display: flex; flex-direction: column; justify-content: center">
                            <p class="ytm-default-text ytm-feedback">
                                Балл за задание:
                            </p>
                        </div>
                        <p class="ytm-default-text ytm-feedback" v-if="!feedbackEditStatus[index].editing">
                            {{ attempt.feedback[index].score }}
                        </p>
                        <Input
                            v-else
                            v-model="feedbackEditStatus[index].score"
                            type="number"
                            step="0.01"
                            style="max-width: 20rem"
                            autofocus
                            @keyup.enter="saveScore(index)"
                        />
                        <div style="display: flex; flex-direction: column; justify-content: center">
                            <i
                                v-if="!feedbackEditStatus[index].editing"
                                class="fa-solid fa-pen-to-square"
                                style="margin-left: 0.5rem; cursor: pointer; color: #07A8F0"
                                @click="editScore(index)"
                            />
                            <div v-else>
                                <i
                                    class="fa-solid fa-save"
                                    style="margin-left: 0.5rem; cursor: pointer; color: #07A8F0"
                                    @click="saveScore(index)"
                                />
                                <i
                                    class="fa-solid fa-xmark ytm-red"
                                    style="margin-left: 0.5rem; cursor: pointer"
                                    @click="discardEditing(index)"
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </ExpandCollapseTransition>
    </div>
</template>

<script>
import "@/styles/style.css";
import ExpandCollapseTransition from "@/components/redesign/Transitions/ExpandCollapseTransition.vue";
import ShortAnswerResult from "@/components/MaterialsV2/Test/TestView/ShortAnswerResult.vue";
import SingleChoiceResult from "@/components/MaterialsV2/Test/TestView/SingleChoiceResult.vue";
import DetailedAnswerResult from "@/components/MaterialsV2/Test/TestView/DetailedAnswerResult.vue";
import MultipleChoiceResult from "@/components/MaterialsV2/Test/TestView/MultipleChoiceResult.vue";
import Input from "@/components/redesign/Form/Input.vue";
import axios from "axios";
import {SERVICE_MATERIALS_URI} from "@/util/api-host";
import {authHeader} from "@/util/auth-header";

export default {
    name: 'AttemptViewForAdmin',
    components: {
        Input,
        MultipleChoiceResult,
        DetailedAnswerResult,
        SingleChoiceResult,
        ShortAnswerResult,
        ExpandCollapseTransition},
    props: {
        testId: {
            type: String,
            required: true,
        },
        test: {
            type: Object,
            required: true,
        },
        attempt: {
            type: Object,
            required: true,
        },
    },
    data() {
        return {
            ready: false,
            showAttempt: false,
            feedbackEditStatus: [],
        };
    },
    methods: {
        toggleShowAttempt() {
            if (this.attempt.finished) {
                this.showAttempt = !this.showAttempt;
                if (this.showAttempt) {
                    this.$refs.icon.classList.add('ytm-attempt-view-icon-rotate');
                } else {
                    this.$refs.icon.classList.remove('ytm-attempt-view-icon-rotate');
                }
            }
        },
        editScore(index) {
            this.feedbackEditStatus[index].editing = true;
        },
        discardEditing(index) {
            this.feedbackEditStatus[index].editing = false;
            this.feedbackEditStatus[index].score = this.attempt.feedback[index].score;
        },
        saveScore(index) {
            this.$store.dispatch('auth/autoLogin').then(() => {
                this.feedbackEditStatus[index].score = (parseFloat(this.feedbackEditStatus[index].score) || 0);
                this.attempt.feedback[index].score = this.feedbackEditStatus[index].score;
                axios.put(
                    SERVICE_MATERIALS_URI + '/test/attempt/feedback/update',
                    {
                        attempt_id: this.attempt.id,
                        new_feedback: this.attempt.feedback,
                    },
                    {
                        headers: authHeader(),
                    },
                ).then(resp => {
                    this.feedbackEditStatus[index].editing = false;
                });
            });
        },
    },
    mounted() {
        if (this.attempt.finished) {
            if (!this.attempt.answers) {
                this.attempt.answers = [];
            }
            for (let i = 0; i < this.attempt.answers.length; i++) {
                this.feedbackEditStatus.push({
                    editing: false,
                    score: this.attempt.feedback[i].score,
                });
            }
        }
        this.ready = true;
    },
};
</script>

<style scoped>
.ytm-attempt-view {
    padding: 0.4rem 1rem;
    border-left: 3px solid #e9ecef;
    border-radius: 3px;
    transition: 0.1s all ease-in-out;
}
.ytm-attempt-view:hover {
    border-color: #68cef8;
}
.ytm-attempt-head {
    display: flex;
    gap: 1rem;
    cursor: pointer;
}
.ytm-attempt-view-icon-wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}
.ytm-attempt-view-icon {
    height: 1rem;
    transition: all .2s ease-in-out;
}
.ytm-attempt-view-icon-rotate {
    transform: rotate(90deg);
}
.ytm-questions-wrapper {
    display: flex;
    flex-direction: column;
    gap: 1rem;
}
.ytm-question-wrapper-v2 {
    padding: 0.25rem 1rem 0.75rem 1rem;
    border-left: 3px solid #e9ecef;
    border-radius: 3px;
    transition: 0.1s all ease-in-out;
    margin-bottom: 1rem;
}
.ytm-question-wrapper-v2:hover {
    border-color: #68cef8;
}
.ytm-question-label {
    font-size: 1.2rem;
    font-weight: 600;
    margin-bottom: 1rem;
}
.ytm-feedback {
    font-size: 0.9rem;
    font-weight: 600;
    white-space: nowrap;
}
</style>