<template>
    <div class="ytm-questions-wrapper">
        <div
            v-for="(question, index) in test.questions"
            :key="question.id"
            class="ytm-question-wrapper-v2"
            style="margin-bottom: 0"
        >
            <p class="ytm-default-text ytm-question-label">
                Вопрос {{ index + 1 }}
            </p>
            <SingleChoiceResult
                v-if="question.type === 'single_choice'"
                :question="question.single_choice"
                :answer="attempt.answers[index].single_choice.answer"
                style="margin-bottom: 1rem"
            />
            <MultipleChoiceResult
                v-if="question.type === 'multiple_choice'"
                :question="question.multiple_choice"
                :answer="attempt.answers[index].multiple_choice.answer"
                style="margin-bottom: 1rem"
            />
            <ShortAnswerResult
                v-if="question.type === 'short_answer'"
                :question="question.short_answer"
                :answer="attempt.answers[index].short_answer.answer"
            />
            <DetailedAnswerResult
                v-if="question.type === 'detailed_answer'"
                :questionId="`${testId}:${index}`"
                :question="question.detailed_answer"
                :answer="attempt.answers[index].detailed_answer.answer"
                :board="attempt.answers[index].detailed_answer.board"
            />
            <p class="ytm-default-text ytm-feedback">
                Ваш балл: {{ attempt.feedback[index].score }}
            </p>
        </div>
    </div>
</template>

<script>
import "@/styles/style.css";
import SingleChoiceResult from "@/components/MaterialsV2/Test/TestView/SingleChoiceResult.vue";
import MultipleChoiceResult from "@/components/MaterialsV2/Test/TestView/MultipleChoiceResult.vue";
import ShortAnswerResult from "@/components/MaterialsV2/Test/TestView/ShortAnswerResult.vue";
import DetailedAnswerResult from "@/components/MaterialsV2/Test/TestView/DetailedAnswerResult.vue";

export default {
    name: 'TestResultForParticipant',
    components: {DetailedAnswerResult, ShortAnswerResult, MultipleChoiceResult, SingleChoiceResult},
    props: {
        testId: {
            type: String,
            required: true,
        },
        test: {
            type: Object,
            required: true,
        },
        attempt: {
            type: Object,
            required: true,
        },
    },
};
</script>

<style scoped>
.ytm-questions-wrapper {
    display: flex;
    flex-direction: column;
    gap: 1rem;
}
.ytm-question-wrapper-v2 {
    padding: 0.25rem 1rem 0.75rem 1rem;
    border-left: 3px solid #e9ecef;
    border-radius: 3px;
    transition: 0.1s all ease-in-out;
    margin-bottom: 1rem;
}
.ytm-question-wrapper-v2:hover {
    border-color: #68cef8;
}
.ytm-question-label {
    font-size: 1.2rem;
    font-weight: 600;
    margin-bottom: 1rem;
}
.ytm-feedback {
    font-size: 0.9rem;
    font-weight: 600;
}
</style>