<template>
    <div>
        <p class="ytm-default-text" style="font-size: 1.4rem; font-weight: 600; margin-bottom: 1rem">
            {{test.title}}
        </p>
        <p v-if="test.deadline" class="ytm-default-text ytm-deadline" style="margin-bottom: 1rem">
            Крайний срок прохождения теста – {{getDeadlineString(test.deadline)}}
        </p>
        <p v-else class="ytm-default-text ytm-deadline" style="margin-bottom: 1rem">
            Крайний срок прохождения теста не задан
        </p>
        <div v-if="state.isTestFinished || state.attemptStatus === 'finished'">
            <p class="ytm-default-text" style="font-size: 1.1rem; font-weight: 500; margin-top: 1.5rem">
                🎉 Поздравляем, тест завершён!
            </p>
            <div v-if="ableToShowResults" style="margin-top: 1rem">
                <div v-if="!state.gotAttempt" style="display: flex">
                    <div class="ytm-default-text ytm-black-button ytm-start-button" @click="showResults">
                        Показать результаты
                    </div>
                </div>
                <TestResultForParticipant
                    v-else
                    :testId="testId"
                    :test="test"
                    :attempt="state.attempt"
                />
            </div>
            <div v-else style="margin-top: 0.5rem">
                <p class="ytm-default-text ytm-deadline">
                    Результаты будут доступны после окончания теста
                </p>
            </div>
        </div>
        <div v-else-if="state.attemptStatus === 'no_attempt'" class="ytm-before-start">
            <div class="ytm-default-text ytm-black-button ytm-start-button" @click="startTest">
                Приступить!
            </div>
        </div>
        <div v-else-if="state.attemptStatus === 'in_progress'">
            <TestInProgress
                v-if="state.gotAttempt && state.gotQuestions"
                :testId="testId"
                :test="test"
                :attempt="state.attempt"
                @saveAttempt="saveAttempt"
                @finishTest="finishTest"
            />
            <div v-else class="ytm-questions-skeletons">
                <div class="skeleton ytm-question-skeleton"/>
                <div class="skeleton ytm-question-skeleton"/>
                <div class="skeleton ytm-question-skeleton"/>
            </div>
        </div>
    </div>
</template>

<script>
import "@/components/CoursePageV2/skeletons.css";
import {getHHMM, getStrDate} from "@/util/datetime-to-str";
import {computed, onBeforeUnmount, onMounted, reactive} from "vue";
import TestInProgress from "@/components/MaterialsV2/Test/TestView/TestInProgress.vue";
import axios from "axios";
import {SERVICE_MATERIALS_URI} from "@/util/api-host";
import {authHeader} from "@/util/auth-header";
import {useStore} from "vuex";
import TestResultForParticipant from "@/components/MaterialsV2/Test/TestView/TestResultForParticipant.vue";

export default {
    name: 'TestViewForParticipant',
    components: {TestResultForParticipant, TestInProgress},
    props: {
        testId: {
            type: String,
            required: true,
        },
        test: {
            type: Object,
            required: true,
        },
    },
    setup(props) {
        const store = useStore();

        const now = new Date();
        const state = reactive({
            gotAttempt: false,
            gotQuestions: false,
            attemptStatus: props.test.additional_info.attempt_status,
            isTestFinished: props.test.deadline && now >= new Date(props.test.deadline),
            attempt: null,
        });

        if (props.test.deadline) {
            props.test.deadline = new Date(props.test.deadline);
        }

        const ableToShowResults = computed(
            () => state.isTestFinished || (!props.test.deadline && state.attemptStatus === 'finished')
        );

        const getQuestions = (needAnswers) => {
            store.dispatch('auth/autoLogin').then(() => {
                axios.get(
                    SERVICE_MATERIALS_URI + '/test/questions',
                    {
                        params: {
                            test_id: props.testId,
                            answers: needAnswers,
                        },
                        headers: authHeader(),
                    },
                ).then(resp => {
                    props.test.questions = resp.data.questions ? resp.data.questions : resp.data.out;
                    state.gotQuestions = true;
                });
            });
        };
        const getAttempt = () => {
            store.dispatch('auth/autoLogin').then(() => {
                axios.get(
                    SERVICE_MATERIALS_URI + '/test/attempt',
                    {
                        params: {
                            test_id: props.testId,
                        },
                        headers: authHeader(),
                    },
                ).then(resp => {
                    state.attempt = resp.data.attempt ? resp.data.attempt : resp.data.out;
                    state.gotAttempt = true;
                });
            });
        };
        const createAttempt = () => {
            store.dispatch('auth/autoLogin').then(() => {
                axios.post(
                    SERVICE_MATERIALS_URI + '/test/attempt/create',
                    {},
                    {
                        params: {test_id: props.testId},
                        headers: authHeader(),
                    }
                ).then(getAttempt);
            });
        };

        if (state.attemptStatus === 'in_progress') {
            getQuestions(false);
            getAttempt();
        }

        const startTest = () => {
            state.attemptStatus = 'in_progress';
            getQuestions(false);
            createAttempt();
        };

        const updateAttempt = (answers, isFinishing) => {
            store.dispatch('auth/autoLogin').then(() => {
                axios.put(
                    SERVICE_MATERIALS_URI + '/test/attempt/update',
                    {
                        answers: answers,
                        finish: isFinishing,
                    },
                    {
                        params: {
                            test_id: props.testId,
                        },
                        headers: authHeader(),
                    },
                ).then(() => {
                    if (isFinishing) {
                        state.attemptStatus = 'finished';
                        state.attempt = null;
                        state.gotAttempt = false;
                        props.test.questions = null;
                        state.gotQuestions = false;
                    }
                });
            });
        };

        const saveAttempt = () => { updateAttempt(state.attempt.answers, false) };
        const finishTest = () => { updateAttempt(state.attempt.answers, true) };
        const showResults = () => {
            if (ableToShowResults.value) {
                getQuestions(true);
                getAttempt();
            }
        };

        if (props.test.deadline && !state.isTestFinished) {
            let handleTestDeadline = () => {
                state.isTestFinished = true;
                if (state.attemptStatus === 'in_progress') {
                    finishTest();
                }
            };
            let finishTestCallback = null;
            onMounted(() => {
                finishTestCallback = setTimeout(handleTestDeadline, new Date(props.test.deadline).getTime() - now.getTime());
            });
            onBeforeUnmount(() => {
                clearTimeout(finishTestCallback);
            });
        }

        return {state, ableToShowResults, startTest, saveAttempt, finishTest, showResults};
    },
    methods: {
        getDeadlineString(time) {
            let localTime = new Date(time);
            let needYear = true;
            if (localTime.getFullYear() === new Date().getFullYear()) {
                needYear = false;
            }
            return getStrDate(localTime, true, needYear) + ' в ' + getHHMM(localTime);
        },
    },
};
</script>

<style scoped>
.ytm-deadline {
    font-size: 0.9rem;
    color: #6c757d;
}
.ytm-before-start {
    display: flex;
}
.ytm-start-button {
    background-color: #07A8F0;
    color: #FFFFFF;
    font-weight: 600;
}
.ytm-questions-skeletons {
    display: flex;
    flex-direction: column;
    gap: 1rem;
}
.ytm-question-skeleton {
    width: 100%;
    height: 8rem;
    border-radius: 1rem;
}
</style>