<template>
    <div>
        <QuestionHeadView :head="question.head" style="margin-bottom: 0.4rem"/>
        <p class="ytm-default-text ytm-question-desc" style="margin-bottom: 0.9rem">
            Ученик сможет написать ответ текстом или воспользоваться доской
        </p>
        <LightEditor
            v-model="answerDraft"
            placeholder="Развёрнутый ответ"
            @update:modelValue="onAnswerUpdate"
        />
        <div style="margin-top: 1rem">
            <div style="display: flex">
                <p v-if="board === null" class="ytm-default-text" style="font-size: 0.9rem; color: #9E9E9E; cursor: pointer" @click="addBoard">
                    <i class="fa-solid fa-paperclip"/> Добавить доску
                </p>
            </div>
            <ExpandCollapseTransition>
                <div v-if="board !== null">
                    <Board
                        boardId="board"
                        :cellId="questionId"
                        :active="true"
                        :ar="board.ar"
                        :objects="boardObjects"
                        @newAR="onNewAR"
                        @onObjectAddition="onObjectAddition"
                        @onObjectTransform="onObjectTransform"
                        @onObjectDeletion="onObjectDeletion"
                    />
                    <div style="display: flex">
                        <p class="ytm-default-text" style="font-size: 0.9rem; color: #9E9E9E; cursor: pointer" @click="removeBoard">
                            <i class="fa-solid fa-xmark ytm-red"/> Удалить доску
                        </p>
                    </div>
                </div>
            </ExpandCollapseTransition>
        </div>
    </div>
</template>

<script>
import "@/styles/style.css";
import QuestionHeadView from "@/components/MaterialsV2/Test/TestView/QuestionHeadView.vue";
import LightEditor from "@/components/redesign/Form/LightEditor.vue";
import Board from "@/components/redesign/Board/Board.vue";
import {computed, ref} from "vue";
import ExpandCollapseTransition from "@/components/redesign/Transitions/ExpandCollapseTransition.vue";

export default {
    name: 'DetailedAnswerView',
    components: {ExpandCollapseTransition, Board, LightEditor, QuestionHeadView},
    props: {
        questionId: {
            type: String,
            required: true,
        },
        question: {
            type: Object,
            required: true,
        },
        answerDraft: {
            type: String,
            required: true,
        },
        answerBoard: {
            type: [Object, null],
            required: true,
        },
    },
    setup(props, {emit}) {
        const board = computed(() => props.answerBoard);
        const boardObjects = computed(() => props.answerBoard ? props.answerBoard.objects.map(object => object.content) : []);

        const onAnswerUpdate = (value) => {
            emit('update:answerDraft', value);
        };

        const addBoard = () => emit('update:answerBoard', {ar: 0.4, objects: []});
        const removeBoard = () => emit('update:answerBoard', null);

        const onNewAR = (value) => {
            props.answerBoard.ar = value;
            emit('update:answerBoard', props.answerBoard);
        };
        const onObjectAddition = (object) => {
            props.answerBoard.objects.push({uuid: object.uuid, content: object});
            emit('update:answerBoard', props.answerBoard);
        };
        const onObjectTransform = (objects) => {
            for (let i = 0; i < objects.length; i++) {
                for (let j = 0; j < props.answerBoard.objects.length; j++) {
                    if (props.answerBoard.objects[j].uuid === objects[i].uuid) {
                        props.answerBoard.objects[j].content = objects[i];
                        break;
                    }
                }
            }
            emit('update:answerBoard', props.answerBoard);
        };
        const onObjectDeletion = (uuids) => {
            props.answerBoard.objects = props.answerBoard.objects.filter((object) => !uuids.includes(object.uuid));
            emit('update:answerBoard', props.answerBoard);
        };

        return {
            board, boardObjects,
            onAnswerUpdate, addBoard, removeBoard,
            onNewAR, onObjectAddition, onObjectTransform, onObjectDeletion,
        };
    },
};
</script>

<style scoped>
.ytm-question-desc {
    font-size: 0.8rem;
    color: #9E9E9E;
}
</style>