<template>
    <div>
        <LightEditor
            v-model="modelValue.content"
            placeholder="Какой ответ на главный вопрос жизни, вселенной и вообще?"
            autofocus="end"
        />
    </div>
</template>

<script>
import LightEditor from "@/components/redesign/Form/LightEditor.vue";

export default {
    name: 'QuestionHeadEditor',
    components: {LightEditor},
    props: {
        modelValue: {},
    },
};
</script>

<style scoped>

</style>