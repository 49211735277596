<template>
    <div>
        <QuestionHeadView :head="question.head" style="margin-bottom: 1rem"/>
        <InputGroup
                v-model="answer"
                type="text"
                title="Ваш ответ:"
                placeholder="Нет ответа"
                class="ytm-answer-input"
                disabled="true"
                style="margin-bottom: 1rem"
        />
    </div>
</template>

<script>
import "@/styles/style.css";
import QuestionHeadView from "@/components/MaterialsV2/Test/TestView/QuestionHeadView.vue";
import InputGroup from "@/components/redesign/Form/InputGroup.vue";

export default {
    name: 'ShortAnswerResult',
    components: {InputGroup, QuestionHeadView},
    props: {
        question: {
            type: Object,
            required: true,
        },
        answer: {
            type: String,
            required: true,
        },
    },
};
</script>

<style scoped>
.ytm-answer-input {
    width: min(100%, 30rem);
    max-width: min(100%, 30rem);
}
</style>