<template>
    <div>
        <QuestionHeadView :head="question.head" style="margin-bottom: 0.5rem"/>
        <p class="ytm-default-text ytm-question-desc">
            Ученик сможет написать ответ текстом или воспользоваться доской
        </p>
    </div>
</template>

<script>
import "@/styles/style.css";
import QuestionHeadView from "@/components/MaterialsV2/Test/TestView/QuestionHeadView.vue";

export default {
    name: 'DetailedAnswerForAdminView',
    components: {QuestionHeadView},
    props: {
        question: {
            type: Object,
            required: true,
        },
    },
};
</script>

<style scoped>
.ytm-question-desc {
    font-size: 0.8rem;
    color: #9E9E9E;
}
</style>