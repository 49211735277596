<template>
    <div>
        <div v-if="test.deadline" style="margin-bottom: 1rem">
            <VueCountdown
                v-if="test.deadline"
                class="ytm-default-text ytm-test-countdown"
                :time="test.deadline - new Date()"
                :transform="extendToTwoDigits"
                :interval="1000"
                v-slot="{hours, minutes, seconds}"
                @end="finishTest"
            >
                До окончания теста осталось {{hours}}:{{minutes}}:{{seconds}}
            </VueCountdown>
        </div>
        <div class="ytm-questions-wrapper" style="margin-bottom: 1rem">
            <div
                v-for="(question, index) in test.questions"
                :key="question.id"
                class="ytm-question-wrapper-v2"
                style="margin-bottom: 0"
            >
                <p class="ytm-default-text ytm-question-label">
                    Вопрос {{ index + 1 }}
                </p>
                <SingleChoiceView
                    v-if="question.type === 'single_choice'"
                    :question="question.single_choice"
                    v-model:answerDraft="attempt.answers[index].single_choice.answer"
                    @update:answerDraft="onAnswerUpdate"
                />
                <MultipleChoiceView
                    v-if="question.type === 'multiple_choice'"
                    :question="question.multiple_choice"
                    v-model:answerDraft="attempt.answers[index].multiple_choice.answer"
                    @update:answerDraft="onAnswerUpdate"
                />
                <ShortAnswerView
                    v-if="question.type === 'short_answer'"
                    :question="question.short_answer"
                    v-model:answerDraft="attempt.answers[index].short_answer.answer"
                    @update:answerDraft="onAnswerUpdate"
                />
                <DetailedAnswerView
                    v-if="question.type === 'detailed_answer'"
                    :questionId="`${testId}:${index}`"
                    :question="question.detailed_answer"
                    v-model:answerDraft="attempt.answers[index].detailed_answer.answer"
                    v-model:answerBoard="attempt.answers[index].detailed_answer.board"
                    @update:answerDraft="onAnswerUpdate"
                    @update:answerBoard="onAnswerUpdate"
                />
            </div>
        </div>
        <div class="ytm-buttons-wrapper">
            <Transition name="ytm-save-button">
                <div
                    v-if="showSaveButton"
                    class="ytm-transparent-button ytm-default-text"
                    style="color: #07A8F0; font-weight: 600; border-color: #07A8F0; padding: calc(0.6rem - 2px) calc(1.2rem - 2px)"
                    @click="saveAttempt"
                >
                    Сохранить прогресс
                </div>
            </Transition>
            <div
                class="ytm-black-button ytm-default-text"
                style="background: #07A8F0; color: #FFFFFF; font-weight: 600"
                @click="finishTest"
            >
                Завершить
            </div>
        </div>
    </div>
</template>

<script>
import SingleChoiceView from "@/components/MaterialsV2/Test/TestView/SingleChoiceView.vue";
import MultipleChoiceView from "@/components/MaterialsV2/Test/TestView/MultipleChoiceView.vue";
import ShortAnswerView from "@/components/MaterialsV2/Test/TestView/ShortAnswerView.vue";
import DetailedAnswerView from "@/components/MaterialsV2/Test/TestView/DetailedAnswerView.vue";
import VueCountdown from "@chenfengyuan/vue-countdown";

export default {
    name: 'TestInProgress',
    components: {VueCountdown, DetailedAnswerView, ShortAnswerView, MultipleChoiceView, SingleChoiceView},
    props: {
        testId: {
            type: String,
            required: true,
        },
        test: {
            type: Object,
            required: true,
        },
        attempt: {
            type: Object,
            required: true,
        },
    },
    data() {
        return {
            showSaveButton: false,
        };
    },
    methods: {
        extendToTwoDigits(props) {
            const formattedProps = {};
            Object.entries(props).forEach(([key, value]) => {
                formattedProps[key] = key !== 'hours' && value < 10 ? `0${value}` : String(value);
            });
            return formattedProps;
        },
        onAnswerUpdate() {
            this.showSaveButton = true;
        },
        saveAttempt() {
            this.$emit('saveAttempt');
            this.showSaveButton = false;
        },
        finishTest() {
            this.$emit('finishTest');
        },
    },
};
</script>

<style scoped>
.ytm-test-countdown {
    color: #07A8F0;
    font-weight: 600;
}
.ytm-questions-wrapper {
    display: flex;
    flex-direction: column;
    gap: 1rem;
}
.ytm-question-wrapper-v2 {
    padding: 0.25rem 1rem 0.75rem 1rem;
    border-left: 3px solid #e9ecef;
    border-radius: 3px;
    transition: 0.1s all ease-in-out;
    margin-bottom: 1rem;
}
.ytm-question-wrapper-v2:hover {
    border-color: #68cef8;
}
.ytm-question-label {
    font-size: 1.2rem;
    font-weight: 600;
    margin-bottom: 1rem;
}
.ytm-buttons-wrapper {
    display: flex;
    gap: 0.5rem;
}
.ytm-save-button-enter-active,
.ytm-save-button-leave-active {
    transition: opacity .2s ease;
}
.ytm-save-button-enter-from,
.ytm-save-button-leave-to {
    opacity: 0;
}
</style>