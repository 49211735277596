<template>
    <div>
        <QuestionHeadView :head="question.head" style="margin-bottom: 0.4rem"/>
        <p class="ytm-default-text ytm-question-desc" style="margin-bottom: 0.9rem">
            Множественный выбор ответа
        </p>
        <div v-if="ready" class="ytm-answer">
            <AnswerOptionView
                v-for="option in question.answer_options"
                :key="option.id"
                :content="option.content"
                v-model:isChecked="checked[option.id]"
                @update:isChecked="onAnswerUpdate"
            />
        </div>
    </div>
</template>

<script>
import "@/styles/style.css";
import QuestionHeadView from "@/components/MaterialsV2/Test/TestView/QuestionHeadView.vue";
import AnswerOptionView from "@/components/MaterialsV2/Test/TestView/AnswerOptionView.vue";

export default {
    name: 'MultipleChoiceView',
    components: {AnswerOptionView, QuestionHeadView},
    props: {
        question: {
            type: Object,
            required: true,
        },
        answerDraft: {
            type: Array,
            required: true,
        },
    },
    data() {
        return {
            ready: false,
            checked: {},
        };
    },
    methods: {
        onAnswerUpdate() {
            const answerDraft = [];
            for (const [k, v] of Object.entries(this.checked)) {
                if (v) {
                    answerDraft.push(Number(k));
                }
            }
            this.$emit('update:answerDraft', answerDraft);
        },
    },
    mounted() {
        for (const option of this.question.answer_options) {
            this.checked[option.id] = false;
        }
        for (const answer of this.answerDraft) {
            this.checked[answer] = true;
        }
        this.ready = true;
    },
};
</script>

<style scoped>
.ytm-question-desc {
    font-size: 0.8rem;
    color: #9E9E9E;
}
.ytm-answer {
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
}
</style>