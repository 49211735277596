<template>
    <div>
        <p v-html="head.content" class="ytm-default-text"/>
    </div>
</template>

<script>
import "@/styles/style.css";

export default {
    name: 'QuestionHeadView',
    props: {
        head: {
            type: Object,
            required: true,
        },
    },
};
</script>

<style scoped>

</style>