<template>
    <div>
        <QuestionHeadView :head="question.head" style="margin-bottom: 1rem"/>
        <div class="ytm-answer">
            <AnswerOptionWithResult
                v-for="option in question.answer_options"
                :key="option.id"
                :content="option.content"
                :checkedRight="answer === option.id && question.answer === option.id"
                :checkedWrong="answer === option.id && question.answer !== option.id"
            />
        </div>
    </div>
</template>

<script>
import "@/styles/style.css";
import QuestionHeadView from "@/components/MaterialsV2/Test/TestView/QuestionHeadView.vue";
import AnswerOptionWithResult from "@/components/MaterialsV2/Test/TestView/AnswerOptionWithResult.vue";

export default {
    name: 'SingleChoiceResult',
    components: {AnswerOptionWithResult, QuestionHeadView},
    props: {
        question: {
            type: Object,
            required: true,
        },
        answer: {
            type: Number,
            required: true,
        },
    },
};
</script>

<style scoped>
.ytm-answer {
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
}
</style>