<template>
    <div class="ytm-answer-option-view">
        <div class="ytm-choice-wrapper">
            <div :class="{
                'ytm-choice': true,
                'ytm-choice-checked-right': checkedRight,
                'ytm-choice-checked-wrong': checkedWrong,
                'ytm-choice-unchecked-wrong': uncheckedWrong,
            }"/>
        </div>
        <p class="ytm-answer-option-content ytm-default-text" v-html="content"/>
    </div>
</template>

<script>
export default {
    name: 'AnswerOptionWithResult',
    props: {
        content: {},
        checkedRight: {
            type: Boolean,
            required: true,
        },
        checkedWrong: {
            type: Boolean,
            required: true,
        },
        uncheckedWrong: {
            type: Boolean,
            default: false,
        },
    },
};
</script>

<style scoped>
.ytm-answer-option-view {
    width: 100%;
    display: flex;
    cursor: pointer;
}
.ytm-choice-wrapper {
    padding-right: 1rem;
    display: flex;
    align-items: center;
    cursor: pointer;
}
.ytm-choice {
    width: 1rem;
    height: 1rem;
    border: 1px solid #adb5bd;
    border-radius: 0.25rem;
    background-repeat: no-repeat;
    background-position: center center;
    background-size: 50% 50%;
}
.ytm-choice-checked-right {
    border-color: #2EA95F;
    background-color: #FFFFFF;
    background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 8 8'%3e%3cpath fill='%232EA95F' d='M6.564.75l-3.59 3.612-1.538-1.55L0 4.26 2.974 7.25 8 2.193z'/%3e%3c/svg%3e");
}
.ytm-choice-checked-wrong {
    border-color: #F55A5A;
    background-color: #FFFFFF;
    background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 8 8'%3e%3cpath fill='%23F55A5A' d='M6.564.75l-3.59 3.612-1.538-1.55L0 4.26 2.974 7.25 8 2.193z'/%3e%3c/svg%3e");
}
.ytm-choice-unchecked-wrong {
    border-color: #F55A5A;
}
.ytm-answer-option-content {
    flex-grow: 1;
}
</style>