<template>
    <div>
        <QuestionHeadEditor v-model="question.head" style="margin-bottom: 1rem"/>
        <div class="ytm-answer" v-if="ready">
            <VerticalListTransition>
                <AnswerOptionEditor
                    v-for="option in question.answer_options"
                    :key="option.id"
                    v-model:content="option.content"
                    v-model:isChecked="checked[option.id]"
                    @delete="deleteAnswerOption(option.id)"
                    @update:isChecked="onAnswerUpdate"
                />
            </VerticalListTransition>
            <AnswerOptionCreator @click="addNewAnswerOption"/>
        </div>
    </div>
</template>

<script>
import QuestionHeadEditor from "@/components/MaterialsV2/Test/TestEditor/QuestionHeadEditor.vue";
import AnswerOptionEditor from "@/components/MaterialsV2/Test/TestEditor/AnswerOptionEditor.vue";
import AnswerOptionCreator from "@/components/MaterialsV2/Test/TestEditor/AnswerOptionCreator.vue";
import VerticalListTransition from "@/components/redesign/Transitions/VerticalListTransition.vue";

export default {
    name: 'MultipleChoiceEditor',
    components: {VerticalListTransition, AnswerOptionCreator, AnswerOptionEditor, QuestionHeadEditor},
    props: {
        question: {
            type: Object,
            required: true,
        },
    },
    data() {
        return {
            ready: false,
            currOptionId: 1,
            checked: {},
        };
    },
    methods: {
        addNewAnswerOption() {
            const newOption = {
                id: this.currOptionId++,
                content: '',
            };
            this.checked[newOption.id] = false;
            this.question.answer_options.push(newOption);
        },
        deleteAnswerOption(optionId) {
            this.question.answer_options = this.question.answer_options.filter(option => option.id !== optionId);
            delete this.checked[optionId];
            if (this.question.answer === optionId) {
                this.question.answer = this.question.answer.filter(answer => answer !== optionId);
            }
        },
        onAnswerUpdate() {
            this.question.answer = [];
            for (const [k, v] of Object.entries(this.checked)) {
                if (v) {
                    this.question.answer.push(Number(k));
                }
            }
        },
    },
    mounted() {
        for (const option of this.question.answer_options) {
            this.checked[option.id] = false;
            this.currOptionId = Math.max(this.currOptionId, option.id + 1);
        }
        for (const answer of this.question.answer) {
            this.checked[answer] = true;
        }
        this.ready = true;
    },
};
</script>

<style scoped>
.ytm-answer {
    display: flex;
    flex-direction: column;
    gap: 0.8rem;
}
</style>