<template>
    <div class="ytm-answer-option-creator">
        <div class="ytm-choice-wrapper">
            <div class="ytm-choice"/>
        </div>
        <div class="ytm-answer-option-content">
            <p class="ytm-default-text ytm-new-option-cta">
                Добавить новый вариант
            </p>
        </div>
    </div>
</template>

<script>
import "@/styles/style.css";

export default {
    name: 'AnswerOptionCreator',
    methods: {
        toggleIsChecked() {
            this.$emit('update:isChecked', !this.isChecked);
        },
        onUpdate(value) {
            this.$emit('update:content', value);
        },
    },
};
</script>

<style scoped>
.ytm-answer-option-creator {
    width: 100%;
    display: flex;
    user-select: none;
    cursor: pointer;
}
.ytm-choice-wrapper {
    padding-right: 1rem;
    display: flex;
    align-items: center;
    cursor: pointer;
}
.ytm-choice {
    width: 1rem;
    height: 1rem;
    border: 1px solid #adb5bd;
    border-radius: 0.25rem;
    background-repeat: no-repeat;
    background-position: center center;
    background-size: 50% 50%;
}
.ytm-answer-option-content {
    flex-grow: 1;
}
.ytm-new-option-cta {
    color: #6b6b6b;
    font-size: 0.9rem;
}
</style>