<template>
    <div>
        <p class="ytm-default-text" style="font-size: 1.4rem; font-weight: 600; margin-bottom: 1rem">
            {{test.title}}
        </p>
        <p v-if="test.deadline" class="ytm-default-text ytm-deadline" style="margin-bottom: 1.75rem">
            Крайний срок прохождения теста – {{getDeadlineString(test.deadline)}}
        </p>
        <p v-else class="ytm-default-text ytm-deadline" style="margin-bottom: 1.75rem">
            Крайний срок прохождения теста не задан
        </p>
        <div class="ytm-test-admin">
            <div class="ytm-tabs-titles">
                <div
                    :class="{'ytm-tab ytm-default-text ytm-tab-title': true, 'ytm-tab-selected': state.selectedTab === 'questions'}"
                    @click="showQuestions"
                >
                    Вопросы
                </div>
                <div
                    :class="{'ytm-tab ytm-default-text ytm-tab-title': true, 'ytm-tab-selected': state.selectedTab === 'attempts'}"
                    @click="showAttempts"
                >
                    Прогресс участников
                </div>
            </div>
            <div v-if="state.selectedTab" class="ytm-test-admin-tabs-content">
                <div v-if="state.selectedTab === 'questions'">
                    <div v-if="state.gotQuestions" class="ytm-questions-wrapper">
                        <div
                            v-for="(question, index) in test.questions"
                            :key="question.id"
                            class="ytm-question-wrapper-v2"
                            style="margin-bottom: 0"
                        >
                            <p class="ytm-default-text ytm-question-label">
                                Вопрос {{ index + 1 }}
                            </p>
                            <SingleChoiceResult
                                v-if="question.type === 'single_choice'"
                                :question="question.single_choice"
                                :answer="question.single_choice.answer"
                            />
                            <MultipleChoiceResult
                                v-if="question.type === 'multiple_choice'"
                                :question="question.multiple_choice"
                                :answer="question.multiple_choice.answer"
                            />
                            <ShortAnswerForAdminView
                                v-if="question.type === 'short_answer'"
                                :question="question.short_answer"
                            />
                            <DetailedAnswerForAdminView
                                v-if="question.type === 'detailed_answer'"
                                :question="question.detailed_answer"
                            />
                        </div>
                    </div>
                    <div v-else class="ytm-questions-skeletons">
                        <div class="skeleton ytm-question-skeleton"/>
                        <div class="skeleton ytm-question-skeleton"/>
                        <div class="skeleton ytm-question-skeleton"/>
                    </div>
                </div>
                <div v-if="state.selectedTab === 'attempts'">
                    <div v-if="state.gotQuestions && state.gotAttempts">
                        <p v-if="!state.attempts || state.attempts.length === 0" class="ytm-default-text">
                            Кажется, ещё никто не приступил к тесту
                        </p>
                        <div v-else class="ytm-attempts">
                            <AttemptViewForAdmin
                                v-for="attempt in state.attempts"
                                :key="attempt.id"
                                :testId="testId"
                                :test="test"
                                :attempt="attempt"
                            />
                        </div>
                    </div>
                    <div v-else class="ytm-questions-skeletons">
                        <div class="skeleton ytm-attempt-skeleton"/>
                        <div class="skeleton ytm-attempt-skeleton"/>
                        <div class="skeleton ytm-attempt-skeleton"/>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import "@/styles/style.css";
import "@/components/CoursePageV2/skeletons.css";
import {getHHMM, getStrDate} from "@/util/datetime-to-str";
import {reactive} from "vue";
import {useStore} from "vuex";
import axios from "axios";
import {SERVICE_MATERIALS_URI} from "@/util/api-host";
import {authHeader} from "@/util/auth-header";
import AttemptViewForAdmin from "@/components/MaterialsV2/Test/TestView/AttemptViewForAdmin.vue";
import SingleChoiceResult from "@/components/MaterialsV2/Test/TestView/SingleChoiceResult.vue";
import MultipleChoiceResult from "@/components/MaterialsV2/Test/TestView/MultipleChoiceResult.vue";
import ShortAnswerForAdminView from "@/components/MaterialsV2/Test/TestView/ShortAnswerForAdminView.vue";
import DetailedAnswerForAdminView from "@/components/MaterialsV2/Test/TestView/DetailedAnswerForAdminView.vue";

export default {
    name: 'TestViewForAdmin',
    components: {
        DetailedAnswerForAdminView,
        ShortAnswerForAdminView, MultipleChoiceResult, SingleChoiceResult, AttemptViewForAdmin},
    props: {
        testId: {
            type: String,
            required: true,
        },
        test: {
            type: Object,
            required: true,
        },
    },
    setup(props) {
        const store = useStore();

        const state = reactive({
            selectedTab: '',
            gotQuestions: false,
            gotAttempts: false,
            attempts: null,
        });

        const getQuestions = (needAnswers) => {
            store.dispatch('auth/autoLogin').then(() => {
                axios.get(
                    SERVICE_MATERIALS_URI + '/test/questions',
                    {
                        params: {
                            test_id: props.testId,
                            answers: needAnswers,
                        },
                        headers: authHeader(),
                    },
                ).then(resp => {
                    props.test.questions = resp.data.questions ? resp.data.questions : resp.data.out;
                    state.gotQuestions = true;
                });
            });
        };
        const getAttempts = () => {
            store.dispatch('auth/autoLogin').then(() => {
                axios.get(
                    SERVICE_MATERIALS_URI + '/test/attempt/all',
                    {
                        params: {
                            test_id: props.testId,
                        },
                        headers: authHeader(),
                    },
                ).then(resp => {
                    state.attempts = resp.data.attempts ? resp.data.attempts : resp.data.out;
                    state.gotAttempts = true;
                });
            });
        };

        const showQuestions = () => {
            state.selectedTab = 'questions';
            if (!state.gotQuestions) {
                getQuestions(true);
            }
        };
        const showAttempts = () => {
            state.selectedTab = 'attempts';
            if (!state.gotQuestions) {
                getQuestions(true);
            }
            state.attempts = null;
            getAttempts();
        };

        return {state, showQuestions, showAttempts};
    },
    methods: {
        getDeadlineString(time) {
            let localTime = new Date(time);
            let needYear = true;
            if (localTime.getFullYear() === new Date().getFullYear()) {
                needYear = false;
            }
            return getStrDate(localTime, true, needYear) + ' в ' + getHHMM(localTime);
        },
    },
};
</script>

<style scoped>
.ytm-deadline {
    font-size: 0.9rem;
    color: #6c757d;
}
.ytm-test-admin {
    display: flex;
    flex-direction: column;
    gap: 1rem;
}
.ytm-questions-wrapper {
    display: flex;
    flex-direction: column;
    gap: 1rem;
}
.ytm-question-wrapper-v2 {
    padding: 0.25rem 1rem 0.75rem 1rem;
    border-left: 3px solid #e9ecef;
    border-radius: 3px;
    transition: 0.1s all ease-in-out;
    margin-bottom: 1rem;
}
.ytm-question-wrapper-v2:hover {
    border-color: #68cef8;
}
.ytm-question-label {
    font-size: 1.2rem;
    font-weight: 600;
    margin-bottom: 1rem;
}
.ytm-attempts {
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
}
.ytm-questions-skeletons {
    display: flex;
    flex-direction: column;
    gap: 1rem;
}
.ytm-question-skeleton {
    width: 100%;
    height: 8rem;
    border-radius: 1rem;
}
.ytm-attempt-skeleton {
    width: 100%;
    height: 2rem;
    border-radius: 1rem;
}
</style>