export function formEmptySingleChoice() {
    return {
        type: 'single_choice',
        single_choice: {
            head: {
                content: '',
            },
            answer_options: [],
            answer: -1,
            score_correct: 1,
            score_incorrect: 0,
        },
    };
}

export function formEmptyMultipleChoice() {
    return {
        type: 'multiple_choice',
        multiple_choice: {
            head: {
                content: '',
            },
            answer_options: [],
            answer: [],
            score_rule: 'absolute',
            absolute: {
                score_correct: 1,
                score_incorrect: 0,
            },
        },
    };
}

export function formEmptyShortAnswer() {
    return {
        type: 'short_answer',
        short_answer: {
            head: {
                content: '',
            },
            possible_answers: [],
            score_correct: 1,
            score_incorrect: 0,
        },
    };
}

export function formEmptyDetailedAnswer() {
    return {
        type: 'detailed_answer',
        detailed_answer: {
            head: {
                content: '',
            },
        },
    };
}

export function turnIntoSingleChoice(question) {
    if (question.type === 'single_choice') {
        return question;
    }

    const out = formEmptySingleChoice();
    out.id = question.id;

    switch (question.type) {
        case 'multiple_choice':
            out.single_choice.head = question.multiple_choice.head;
            out.single_choice.answer_options = question.multiple_choice.answer_options;
            if (question.multiple_choice.answer && question.multiple_choice.answer.length === 1) {
                out.single_choice.answer = question.multiple_choice.answer[0];
            }
            break;
        case 'short_answer':
            out.single_choice.head = question.short_answer.head;
            break;
        case 'detailed_answer':
            out.single_choice.head = question.detailed_answer.head;
            break;
    }

    return out;
}

export function turnIntoMultipleChoice(question) {
    if (question.type === 'multiple_choice') {
        return question;
    }

    const out = formEmptyMultipleChoice();
    out.id = question.id;

    switch (question.type) {
        case 'single_choice':
            out.multiple_choice.head = question.single_choice.head;
            out.multiple_choice.answer_options = question.single_choice.answer_options;
            if (question.single_choice.answer !== -1) {
                out.multiple_choice.answer = [question.single_choice.answer];
            }
            break;
        case 'short_answer':
            out.multiple_choice.head = question.short_answer.head;
            break;
        case 'detailed_answer':
            out.multiple_choice.head = question.detailed_answer.head;
            break;
    }

    return out;
}

export function turnIntoShortAnswer(question) {
    if (question.type === 'short_answer') {
        return question;
    }

    const out = formEmptyShortAnswer();
    out.id = question.id;

    switch (question.type) {
        case 'single_choice':
            out.short_answer.head = question.single_choice.head;
            break;
        case 'multiple_choice':
            out.short_answer.head = question.multiple_choice.head;
            break;
        case 'detailed_answer':
            out.short_answer.head = question.detailed_answer.head;
            break;
    }

    return out;
}

export function turnIntoDetailedAnswer(question) {
    if (question.type === 'detailed_answer') {
        return question;
    }

    const out = formEmptyDetailedAnswer();
    out.id = question.id;

    switch (question.type) {
        case 'single_choice':
            out.detailed_answer.head = question.single_choice.head;
            break;
        case 'multiple_choice':
            out.detailed_answer.head = question.multiple_choice.head;
            break;
        case 'short_answer':
            out.detailed_answer.head = question.short_answer.head;
            break;
    }

    return out;
}