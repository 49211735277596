<template>
    <div class="ytm-material-wrapper">
        <TestEditorV2 editorTitle="Создать новый тест" :testData="draft" ref="editor"/>
        <div style="display: flex; gap: 0.5rem; margin-top: 1rem">
            <div
                class="ytm-transparent-button ytm-default-text"
                style="color: #07A8F0; font-weight: 600; border-color: #07A8F0"
                @click="$emit('save_draft')"
            >
                Сохранить черновик
            </div>
            <div
                v-if="ableToPublish"
                class="ytm-black-button ytm-default-text"
                style="background: #07A8F0; color: #FFFFFF; font-weight: 600"
                @click="$emit('publish')"
            >
                Опубликовать
            </div>
        </div>
    </div>
</template>

<script>
import "@/styles/style.css";
import TestEditorV2 from "@/components/MaterialsV2/Test/TestEditor/TestEditorV2.vue";

export default {
    name: 'TestDraft',
    components: {TestEditorV2},
    props: {
        draft: {
            type: [Object, null],
            required: true,
        },
        ableToPublish: {
            type: Boolean,
            default: false,
        },
    },
};
</script>

<style scoped>
.ytm-material-wrapper {
    margin: 0 0 1rem;
    padding: 0.75rem 1rem;
    border-radius: 5px;
    border: 1px solid #e9ecef;
    transition: 0.1s all ease-in-out;
}

.ytm-material-wrapper:hover {
    border-color: #68cef8;
}
</style>