<template>
    <div class="ytm-question-wrapper-v2">
        <div style="display: flex; margin-bottom: 1rem">
            <div style="display: table; margin-right: 0.5rem">
                <p v-if="label" class="ytm-default-text ytm-question-label" style="display: table-cell; vertical-align: middle">
                    {{label}}
                </p>
            </div>
            <div style="flex-grow: 1; display: table; margin-right: 0.5rem">
                <div style="display: table-cell; vertical-align: middle">
                    <i class="fa-solid fa-trash-can ytm-red" style="cursor: pointer" @click="deleteQuestion()"/>
                </div>
            </div>
            <YTMSelect v-model="selectedType" :options="questionTypes" @update:modelValue="onQuestionTypeUpdate"/>
        </div>
        <SingleChoiceEditor v-if="modelValue.type === 'single_choice' && modelValue.single_choice" v-model:question="modelValue.single_choice"/>
        <MultipleChoiceEditor v-if="modelValue.type === 'multiple_choice' && modelValue.multiple_choice" v-model:question="modelValue.multiple_choice"/>
        <ShortAnswerEditor v-if="modelValue.type === 'short_answer' && modelValue.short_answer" v-model:question="modelValue.short_answer"/>
        <DetailedAnswerEditor v-if="modelValue.type === 'detailed_answer' && modelValue.detailed_answer" v-model:question="modelValue.detailed_answer"/>
    </div>
</template>

<script>
import "@/styles/style.css";
import YTMSelect from "@/components/redesign/Form/YTMSelect.vue";
import SingleChoiceEditor from "@/components/MaterialsV2/Test/TestEditor/SingleChoiceEditor.vue";
import MultipleChoiceEditor from "@/components/MaterialsV2/Test/TestEditor/MultipleChoiceEditor.vue";
import ShortAnswerEditor from "@/components/MaterialsV2/Test/TestEditor/ShortAnswerEditor.vue";
import DetailedAnswerEditor from "@/components/MaterialsV2/Test/TestEditor/DetailedAnswerEditor.vue";
import {
    turnIntoDetailedAnswer,
    turnIntoMultipleChoice,
    turnIntoShortAnswer,
    turnIntoSingleChoice
} from "@/components/MaterialsV2/Test/TestEditor/questions";

export default {
    name: 'QuestionEditor',
    components: {DetailedAnswerEditor, ShortAnswerEditor, MultipleChoiceEditor, SingleChoiceEditor, YTMSelect},
    props: {
        modelValue: {},
        label: {
            type: String,
            default: null,
        },
    },
    data() {
        return {
            text: '',
            questionTypes: [
                {value: 'single_choice', desc: 'Один из вариантов'},
                {value: 'multiple_choice', desc: 'Несколько из вариантов'},
                {value: 'short_answer', desc: 'Короткий ответ'},
                {value: 'detailed_answer', desc: 'Подробный ответ'},
            ],
            selectedType: 'single_choice',
        };
    },
    methods: {
        onQuestionTypeUpdate() {
            switch (this.selectedType) {
                case 'single_choice':
                    this.$emit('update:modelValue', turnIntoSingleChoice(this.modelValue));
                    break;
                case 'multiple_choice':
                    this.$emit('update:modelValue', turnIntoMultipleChoice(this.modelValue));
                    break;
                case 'short_answer':
                    this.$emit('update:modelValue', turnIntoShortAnswer(this.modelValue));
                    break;
                case 'detailed_answer':
                    this.$emit('update:modelValue', turnIntoDetailedAnswer(this.modelValue));
                    break;
            }
        },
        deleteQuestion() {
            this.$emit('delete');
        },
    },
};
</script>

<style scoped>
.ytm-question-wrapper-v2 {
    padding: 0.25rem 1rem 0.75rem 1rem;
    border-left: 3px solid #e9ecef;
    border-radius: 3px;
    transition: 0.1s all ease-in-out;
}

.ytm-question-wrapper-v2:hover {
    border-color: #68cef8;
}

.ytm-question-label {
    font-size: 1.2rem;
    font-weight: 600;
}
</style>