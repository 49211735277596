<template>
    <div>
        <QuestionHeadView :head="question.head" style="margin-bottom: 1rem"/>
        <p class="ytm-default-text ytm-input-group-title">
            Ваш ответ:
        </p>
        <LightEditor
                v-model="answer"
                placeholder="Развёрнутый ответ"
                :editable="false"
                style="margin-bottom: 1rem"
        />
        <Board
            v-if="board !== null"
            boardId="board"
            :cellId="questionId"
            :active="false"
            :ar="board.ar"
            :objects="boardObjects"
            style="margin-bottom: 1rem"
        />
    </div>
</template>

<script>
import "@/styles/style.css";
import "@/components/redesign/Form/forms.css";
import QuestionHeadView from "@/components/MaterialsV2/Test/TestView/QuestionHeadView.vue";
import AnswerOptionView from "@/components/MaterialsV2/Test/TestView/AnswerOptionView.vue";
import LightEditor from "@/components/redesign/Form/LightEditor.vue";
import Board from "@/components/redesign/Board/Board.vue";
import {computed} from "vue";

export default {
    name: 'DetailedAnswerResult',
    components: {Board, LightEditor, AnswerOptionView, QuestionHeadView},
    props: {
        questionId: {
            type: String,
            required: true,
        },
        question: {
            type: Object,
            required: true,
        },
        answer: {
            type: String,
            required: true,
        },
        board: {
            type: [Object, null],
            required: true,
        },
    },
    setup(props) {
        const boardObjects = computed(() => props.board ? props.board.objects.map(object => object.content) : []);
        return {boardObjects};
    },
};
</script>

<style scoped>

</style>