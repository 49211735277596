<template>
    <div>
        <QuestionHeadEditor v-model="question.head" style="margin-bottom: 0.8rem"/>
        <p v-if="!question.possible_answers || question.possible_answers.length === 0" class="ytm-default-text ytm-small-text">
            Укажите хотя бы один возможный короткий ответ
        </p>
        <div v-else class="ytm-possible-answers">
            <HorizontalListTransition>
                <p v-for="answer in question.possible_answers" :key="answer.id" class="ytm-default-text ytm-possible-answer">
                    {{answer.value}}
                    <i class="fa-solid fa-xmark ytm-delete-option ytm-red" @click="deleteAnswerOption(answer.id)"/>
                </p>
            </HorizontalListTransition>
        </div>
        <div class="ytm-answer-box">
            <Input
                v-model="value"
                type="text"
                placeholder="Вариант ответа"
                class="ytm-answer-input"
                @keyup.enter="addNewAnswerOption"
                ref="answerBox"
            />
            <div class="ytm-plus-wrapper" @click="addNewAnswerOption">
                <i class="fa-solid fa-plus ytm-plus"/>
            </div>
        </div>
    </div>
</template>

<script>
import "@/styles/style.css";
import QuestionHeadEditor from "@/components/MaterialsV2/Test/TestEditor/QuestionHeadEditor.vue";
import Input from "@/components/redesign/Form/Input.vue";
import HorizontalListTransition from "@/components/redesign/Transitions/HorizontalListTransition.vue";

export default {
    name: 'ShortAnswerEditor',
    components: {HorizontalListTransition, Input, QuestionHeadEditor},
    props: {
        question: {
            type: Object,
            required: true,
        },
    },
    data() {
        return {
            currOptionId: 1,
            value: '',
        };
    },
    methods: {
        addNewAnswerOption() {
            if (!this.value) {
                return;
            }
            this.question.possible_answers.push({
                id: this.currOptionId++,
                value: this.value,
                type: 'string',
            });
            this.value = '';
            this.$refs.answerBox.$el.focus();
        },
        deleteAnswerOption(id) {
            this.question.possible_answers = this.question.possible_answers.filter(value => value.id !== id);
        },
    },
};
</script>

<style scoped>
.ytm-small-text {
    font-size: 0.8rem;
    color: #9E9E9E;
}
.ytm-possible-answers {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    gap: 0.5rem;
    overflow-x: scroll;
}
.ytm-possible-answer {
    border: 1px solid #07A8F0;
    border-radius: 0.5rem;
    padding: 0.2rem 0.5rem;
    white-space: pre;
}
.ytm-delete-option {
    margin-left: 0.2rem;
    font-size: 1rem;
    cursor: pointer;
}
.ytm-answer-box {
    display: flex;
    margin-top: 0.8rem;
    gap: 0.8rem;
    flex-wrap: nowrap;
}
.ytm-answer-input {
    width: min(100%, 30rem);
    max-width: min(100%, 30rem);
}
.ytm-plus-wrapper {
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
}
.ytm-plus {
    color: #07A8F0;
    font-size: 1.5rem;
}
</style>