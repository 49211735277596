export default function validateTest(test) {
    if (!test.title) {
        return 'Не заполнено обязательное поле – название теста';
    }
    if (!test.questions || test.questions.length === 0) {
        return 'В тесте должен быть хотя бы один вопрос';
    }

    for (const [i, question] of test.questions.entries()) {
        switch (question.type) {
            case 'single_choice':
                const singleChoice = question.single_choice;
                if (!singleChoice.head || !singleChoice.head.content || singleChoice.head.content === '' || singleChoice.head.content === '<p></p>') {
                    return `В вопросе ${i + 1} не заполнено обязательное поле – текст вопроса`;
                }
                if (!singleChoice.answer_options || singleChoice.answer_options.length === 0) {
                    return `В вопросе ${i + 1} не указано ни одного варианта ответа`;
                }
                for (const [j, option] of singleChoice.answer_options.entries()) {
                    if (!option.content || option.content === '' || option.content === '<p></p>') {
                        return `В вопросе ${i + 1} есть незаполненный вариант ответа`;
                    }
                }
                if (!singleChoice.answer || singleChoice.answer === -1) {
                    return `В вопросе ${i + 1} не указан ответ`;
                }
                break;
            case 'multiple_choice':
                const multipleChoice = question.multiple_choice;
                if (!multipleChoice.head || !multipleChoice.head.content || multipleChoice.head.content === '' || multipleChoice.head.content === '<p></p>') {
                    return `В вопросе ${i + 1} не заполнено обязательное поле – текст вопроса`;
                }
                if (!multipleChoice.answer_options || multipleChoice.answer_options.length === 0) {
                    return `В вопросе ${i + 1} не указано ни одного варианта ответа`;
                }
                for (const [j, option] of multipleChoice.answer_options.entries()) {
                    if (!option.content || option.content === '' || option.content === '<p></p>') {
                        return `В вопросе ${i + 1} есть незаполненный вариант ответа`;
                    }
                }
                break;
            case 'short_answer':
                const shortAnswer = question.short_answer;
                if (!shortAnswer.head || !shortAnswer.head.content || shortAnswer.head.content === '' || shortAnswer.head.content === '<p></p>') {
                    return `В вопросе ${i + 1} не заполнено обязательное поле – текст вопроса`;
                }
                if (!shortAnswer.possible_answers || shortAnswer.possible_answers.length === 0) {
                    return `В вопросе ${i + 1} не указано ни одного варианта ответа`;
                }
                break;
            case 'detailed_answer':
                const detailedAnswer = question.detailed_answer;
                if (!detailedAnswer.head || !detailedAnswer.head.content || detailedAnswer.head.content === '' || detailedAnswer.head.content === '<p></p>') {
                    return `В вопросе ${i + 1} не заполнено обязательное поле – текст вопроса`;
                }
                break;
            default:
                return `Вопрос ${i + 1} – неизвестный тип вопроса`;
        }
    }

    return '';
}