<template>
    <div>
        <QuestionHeadView :head="question.head" style="margin-bottom: 1rem"/>
        <Input
                v-model="answerDraft"
                type="text"
                placeholder="Короткий ответ"
                class="ytm-answer-input"
                @update:modelValue="onAnswerUpdate"
        />
    </div>
</template>

<script>
import QuestionHeadView from "@/components/MaterialsV2/Test/TestView/QuestionHeadView.vue";
import Input from "@/components/redesign/Form/Input.vue";

export default {
    name: 'ShortAnswerView',
    components: {Input, QuestionHeadView},
    props: {
        question: {
            type: Object,
            required: true,
        },
        answerDraft: {
            type: String,
            required: true,
        },
    },
    methods: {
        onAnswerUpdate(newVal) {
            this.$emit('update:answerDraft', newVal);
        },
    },
};
</script>

<style scoped>
.ytm-answer-input {
    width: min(100%, 30rem);
    max-width: min(100%, 30rem);
}
</style>