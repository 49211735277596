<template>
    <div>
        <p class="ytm-default-text" style="font-size: 1.4rem; font-weight: 600; margin-bottom: 1rem">
            {{editorTitle}}
        </p>
        <InputGroup
            class="ytm-modal-input"
            v-model="test.title"
            title="Название"
            placeholder="Название теста"
            :focus="true"
        />
        <DatetimeGroup
            class="ytm-modal-input"
            v-model="test.deadline"
            @update:modelValue="onDeadlineUpdate"
            title="Крайний срок выполнения"
            placeholder="Оставьте пустым, если нет крайнего срока"
        />
        <VerticalListTransition>
            <div
                v-for="(question, index) in test.questions"
                :key="question.id"
            >
                <QuestionEditor
                    v-model="test.questions[index]"
                    :label="`Вопрос ${index + 1}`"
                    @delete="deleteQuestion(question.id)"
                    style="margin-bottom: 1rem"
                />
            </div>
        </VerticalListTransition>
        <div class="ytm-black-button" style="display: table; padding: 0.4rem 1.2rem" @click="newQuestion()">
            <svg viewBox="0 0 35 35" fill="none" xmlns="http://www.w3.org/2000/svg" style="height: 1.2rem; display: table-cell; vertical-align: middle; margin-right: 0.8rem">
                <path fill-rule="evenodd" clip-rule="evenodd" d="M20.5 3C20.5 1.61929 19.3807 0.5 18 0.5C16.6193 0.5 15.5 1.61929 15.5 3V14.5H3C1.61929 14.5 0.5 15.6193 0.5 17C0.5 18.3807 1.61929 19.5 3 19.5H15.5V32C15.5 33.3807 16.6193 34.5 18 34.5C19.3807 34.5 20.5 33.3807 20.5 32V19.5H32C33.3807 19.5 34.5 18.3807 34.5 17C34.5 15.6193 33.3807 14.5 32 14.5H20.5V3Z" fill="white"/>
            </svg>
            <span class="ytm-default-text ytm-create-course" style="font-weight: 600; color: #FFFFFF; display: table-cell; vertical-align: middle">
                Ещё вопрос
            </span>
        </div>
        <p class="ytm-default-text" style="color: red; font-weight: 600; font-size: 0.9rem; margin-top: 0.8rem">
            {{errMsg}}
        </p>
    </div>
</template>

<script>
import "@/styles/style.css";
import {ref} from "vue";
import InputGroup from "@/components/redesign/Form/InputGroup.vue";
import DatetimeGroup from "@/components/redesign/Form/DatetimeGroup.vue";
import QuestionEditor from "@/components/MaterialsV2/Test/TestEditor/QuestionEditor.vue";
import {formEmptySingleChoice} from "@/components/MaterialsV2/Test/TestEditor/questions";
import VerticalListTransition from "@/components/redesign/Transitions/VerticalListTransition.vue";
import validateTest from "@/components/MaterialsV2/Test/TestEditor/validation";

export default {
    name: 'TestEditor',
    components: {VerticalListTransition, QuestionEditor, DatetimeGroup, InputGroup},
    props: {
        editorTitle: {
            type: String,
            required: true,
        },
        testData: {
            type: [Object, null],
            default: null,
        },
    },
    setup(props) {
        const testData = props.testData || {};
        let test = ref({
            title: testData.title || '',
            start: testData.start || null,
            deadline: testData.deadline || null,
            questions: testData.questions || [],
        });
        let currQuestionId = ref(0);
        for (const question of test.value.questions) {
            if (question.id > currQuestionId.value) {
                currQuestionId.value = question.id;
            }
        }
        currQuestionId.value++;
        const errMsg = ref('');
        return {test, currQuestionId, errMsg};
    },
    methods: {
        validate() {
            this.errMsg = validateTest(this.test);
            return this.errMsg === '';
        },
        getTestData() {
            return this.test;
        },
        onDeadlineUpdate() {
            if (this.test.deadline) {
                this.test.deadline.setSeconds(0, 0);
            }
        },
        newQuestion() {
            const question = formEmptySingleChoice();
            question.id = this.currQuestionId++;
            this.test.questions.push(question);
        },
        deleteQuestion(id) {
            this.test.questions = this.test.questions.filter(value => {
                return value.id !== id;
            });
        },
    },
};
</script>

<style scoped>
.ytm-modal-input {
    margin-bottom: 1.25rem;
}
</style>