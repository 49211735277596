<template>
    <div>
        <QuestionHeadView :head="question.head" style="margin-bottom: 0.8rem"/>
        <p v-if="!question.possible_answers || question.possible_answers.length === 0" class="ytm-default-text ytm-small-text">
            Не указано ни одного варианта
        </p>
        <div v-else class="ytm-possible-answers">
            <p v-for="answer in question.possible_answers" :key="answer.id" class="ytm-default-text ytm-possible-answer">
                {{answer.value}}
            </p>
        </div>
    </div>
</template>

<script>
import "@/styles/style.css";
import QuestionHeadView from "@/components/MaterialsV2/Test/TestView/QuestionHeadView.vue";

export default {
    name: 'ShortAnswerForAdminView',
    components: {QuestionHeadView},
    props: {
        question: {
            type: Object,
            required: true,
        },
    },
};
</script>

<style scoped>
.ytm-small-text {
    font-size: 0.8rem;
    color: #9E9E9E;
}
.ytm-possible-answers {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    gap: 0.5rem;
    overflow-x: scroll;
}
.ytm-possible-answer {
    border: 1px solid #07A8F0;
    border-radius: 0.5rem;
    padding: 0.2rem 0.5rem;
    white-space: pre;
}
</style>